<template>
    <el-upload
        ref="upload"
        :list-type="'text'"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :auto-upload="false"
        :file-list="fileList"
        :limit="1"
        drag
        action=""
        :on-exceed="imageOverLimit"
        accept=".jpg,.jpeg,.png,.JPG,.JPEG,.svg,gif"
    >
        <div class="relative">
            <!-- 呈現字串得上傳框 -->
            <div
                v-if="!defaultImg"
                class="flex items-center justify-center w-full h-[500px] text-red-500 border-2 border-red-500 border-dashed rounded-lg"
            >
                <i class="mr-1 el-icon-plus" style="font-size: 20px"></i
                >行銷圖片
            </div>
            <!-- 呈現預設圖的上傳框 -->
            <img
                v-if="defaultImgData"
                class="w-full max-w-[500px] mx-auto"
                :src="defaultImgData"
                alt=""
            />
        </div>
        <div class="text-center text-gray-300 el-upload__tip">
            只能上傳jpg/jpeg/gif/png/svg 文件，且不超過{{ uploadLimit }}MB
        </div>
    </el-upload>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";

// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.props = {
  // 預設圖片
  defaultImg: {
    type: String,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const emit = __ctx.emit;
  const props = __props;

  // 預設圖片
  const defaultImgData = ref(props.defaultImg);
  watch(() => props.defaultImg, val => {
    defaultImgData.value = val;
  });
  // 上傳檔案列表
  const fileList = ref([]);

  // 上傳檔案
  const photoFile = ref(null);

  // 上傳圖片大小限制
  const uploadLimit = ref(Number(process.env.VUE_APP_UPLOAD_MARKET_PHOTO_SIZE));

  /**
   * 監聽上傳圖片
   * @param { type File(檔案) } file 圖片檔案
   */
  function handleChange(file) {
    if (file.size > Number(uploadLimit.value) * 1024 * 1024) {
      fileList.value = [];
      proxy.$message({
        type: "error",
        message: `圖片尺寸不能大於 ${uploadLimit.value}MB`
      });
      return;
    }
    // 圖片資料
    photoFile.value = null;
    // 檔案列表
    fileList.value = [];
    // 設定圖片為上傳檔案
    photoFile.value = file.raw;
    console.log("photoFile.value =>", photoFile.value);
    // 新增一個讀取上傳檔案方法
    const reader = new FileReader();
    // 讀取上傳檔案
    reader.readAsDataURL(photoFile.value);
    // 將上傳檔案轉為base64
    reader.onload = () => {
      defaultImgData.value = reader.result;
      emit("update:defaultImg", defaultImgData.value);
    };
    emit("update:photoFile", photoFile.value);
  }

  /**
   * 移除圖片
   */
  function handleRemove() {
    photoFile.value = null;
    fileList.value = [];
  }

  /**
   * 上傳圖片超過限制數量
   */
  function imageOverLimit() {
    proxy.$message({
      type: "error",
      message: "超過上傳圖片上限"
    });
  }
  return {
    defaultImgData,
    fileList,
    uploadLimit,
    handleChange,
    handleRemove,
    imageOverLimit
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply border-none;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full overflow-visible;
}
::v-deep .el-upload {
    @apply w-full;
}
::v-deep .el-dialog {
    @screen sm {
        @apply w-[500px];
    }
    @apply w-auto;
}
</style>
