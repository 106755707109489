<template>
    <div class="flex mx-5 my-5">
        <SideBar btnText="+ 新增圖片" @clickBtn="showDialog = true" />
        <!-- {{ datas }} -->
        <section class="w-full mx-10 bg-white rounded-lg">
            <div class="flex justify-center w-full">
                <div class="flex-1 p-5 m-5 border border-gray-200 rounded-lg">
                    <draggable v-model="datas">
                        <div
                            v-for="(data, index) in datas"
                            :key="data.id"
                            class="flex items-center py-5 border-gray-200"
                            :class="
                                index !== datas.length - 1 ? 'border-b' : ''
                            "
                        >
                            <i class="fas fa-equals"></i>
                            <div class="ml-5">
                                <!-- <img
                                    class="w-[150px] rounded-lg"
                                    :src="data.image.url"
                                /> -->
                                <el-image
                                    :src="data.image.url"
                                    class="w-[150px]"
                                    :preview-src-list="[data.image.url]"
                                >
                                </el-image>
                                <div>
                                    id:
                                    <span class="text-blue-500">{{
                                        data.id
                                    }}</span>
                                </div>
                            </div>
                            <div class="flex items-center flex-1">
                                <label class="mx-5 whitespace-nowrap"
                                    >圖片網址：</label
                                >
                                <div>
                                    <div>
                                        <el-input
                                            v-model="datas[index].url"
                                            class="w-[500px]"
                                        ></el-input>
                                    </div>
                                    <span
                                        v-if="
                                            !urlRegex.test(datas[index].url) &&
                                            !$isEmpty(datas[index].url)
                                        "
                                        class="text-red-500"
                                        >請輸入正確網址</span
                                    >
                                </div>
                            </div>
                            <button
                                class="text-xl"
                                @click="
                                    showConfrimDialog = true;
                                    deleteId = data.id;
                                "
                            >
                                <i class="el-icon-delete"></i>
                            </button>
                        </div>
                    </draggable>
                </div>
            </div>
            <div class="flex justify-center my-5">
                <button
                    v-loading="loading"
                    :disabled="loading || !checkAllURL"
                    class="bg-red-500 rounded-lg text-white min-w-[150px] py-2 border border-red-500 myDisabled"
                    @click.prevent="update(datas)"
                >
                    資料更新
                </button>
            </div>
        </section>
        <!-- 裁切時使用彈窗方式 -->
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog"
        >
            <MarketImgUpload
                :photoFile.sync="photoFile"
                :defaultImg.sync="defaultImg"
            />
            <div class="flex items-center mt-2">
                <label class="mr-2 whitespace-nowrap">行銷網址:</label>
                <el-input v-model="url" placeholder="請輸入行銷網址"></el-input>
            </div>
            <div class="flex justify-center my-5">
                <button
                    v-loading="loading"
                    :disabled="
                        $isEmpty(photoFile) ||
                        (!checkUrl && url !== '' && url !== null)
                    "
                    class="min-w-[150px] bg-red-500 border border-red-500 myDisabled text-white py-2 rounded-lg"
                    @click.prevent="add"
                >
                    新增
                </button>
            </div>
        </el-dialog>
        <!-- 確認是否刪除行銷資訊 -->
        <my-dialog
            :showDialog="showConfrimDialog"
            :customClass="'top-1/4 z-[70]'"
            :customBlackBgClass="'z-[60]'"
            @onCloseDialog="closeConfirmDialog"
        >
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <p>確認刪除行銷資訊嗎{{ deleteId }}？</p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeConfirmDialog"
                    >
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteData(deleteId)"
                    >
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
// 測邊選單
import SideBar from "../components/SideBar.vue";
// 行銷圖片上傳
import MarketImgUpload from "./components/MarketImgUpload.vue";
// 導入拖拉排放套件
import draggable from "vuedraggable";
// 彈窗
import myDialog from "@/components/Dialog.vue";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { isEmpty } from "@/service/anyService";

// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const router = useRouter();
  const route = useRoute();
  const loading = ref(false);
  // 行銷資訊
  const datas = ref([]);

  // 行銷圖片檔案
  const photoFile = ref(null);

  // 預設圖片
  const defaultImg = ref(null);

  // 圖片網址
  const url = ref("");
  // 網址正則
  const urlRegex = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  // 驗證是否為正規網址格式
  const checkUrl = computed(() => {
    return urlRegex.test(url.value);
  });
  // 判斷全部 url
  const checkAllURL = computed(() => {
    if (datas.value.length > 0) {
      for (let i = 0; i < datas.value.length; i++) {
        if (!urlRegex.test(datas.value[i].url) && !isEmpty(datas.value[i].url) && datas.value[i].url !== "" && datas.value[i].url !== null) {
          return false;
        }
      }
      return true;
    }
    return false;
  });

  // 顯示上傳圖片彈窗
  const showDialog = ref(false);

  /**
   * 關閉彈窗
   */
  function closeDialog() {
    showDialog.value = false;
    photoFile.value = null;
    defaultImg.value = null;
    url.value = "";
  }

  /**
   * 新增
   */
  async function add() {
    const file = new FormData();
    file.append("image", photoFile.value);
    const resultUploadImg = await uploadImg(file);
    if (resultUploadImg) {
      const resultUpdate = await update(datas.value, true);
      if (resultUpdate) {
        proxy.$message({
          type: "success",
          message: "新增成功"
        });
        closeDialog();
      }
    }
  }

  /**
   * 上傳圖
   * @param { type File(檔案) } file 照片檔案
   */
  async function uploadImg(file) {
    try {
      const {
        data: {
          id
        }
      } = await proxy.$api.MarketUploadImgApi(file);
      datas.value.push({
        id,
        url: isEmpty(url.value) ? null : url.value
      });
      defaultImg.value = null;
      photoFile.value = null;
      return true;
    } catch (err) {
      datas.value = datas.value.splice(0, datas.value.length - 1);
      proxy.$message({
        type: "error",
        message: "上傳圖片失敗"
      });
      return false;
    }
  }

  /**
   * 更新排序與行銷網址
   * @param { type Array(陣列) } datas 行銷資訊
   * @param { type Boolean(布林值) } isAdd 判斷是否新增
   */
  async function update(datas, isAdd = false) {
    loading.value = true;
    try {
      await proxy.$api.MarketUpdateApi(datas);
      await getList();
      if (!isAdd) {
        proxy.$message({
          type: "success",
          message: "更新成功"
        });
        closeDialog();
      }
      return true;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "更新失敗"
      });
      return false;
    } finally {
      loading.value = false;
    }
  }

  /**
   * 取得列表
   */
  async function getList() {
    loading.value = true;
    try {
      const {
        data: {
          modals
        }
      } = await proxy.$api.MarketListApi();
      datas.value = modals;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得行銷資訊失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  // 刪除行銷資訊 id
  const deleteId = ref(null);
  // 確認刪除行銷資訊彈窗
  const showConfrimDialog = ref(false);
  /**
   * 關閉確認刪除行銷資訊彈窗
   */
  function closeConfirmDialog() {
    showConfrimDialog.value = false;
    deleteId.value = null;
  }
  /**
   * 刪除
   * @param { type String(字串) } id 行銷資訊 id
   */
  async function deleteData(id) {
    loading.value = true;
    try {
      await proxy.$api.MarketDeleteApi(id);
      await getList();
      closeConfirmDialog();
      proxy.$message({
        type: "success",
        message: "刪除成功"
      });
    } catch (err) {
      console.log("delete err =>", err);
      proxy.$message({
        type: "error",
        message: "刪除失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  onMounted(async () => {
    await getList();
  });
  return {
    loading,
    datas,
    photoFile,
    defaultImg,
    url,
    urlRegex,
    checkUrl,
    checkAllURL,
    showDialog,
    closeDialog,
    add,
    update,
    deleteId,
    showConfrimDialog,
    closeConfirmDialog,
    deleteData
  };
};
__sfc_main.components = Object.assign({
  SideBar,
  draggable,
  MarketImgUpload,
  myDialog
}, __sfc_main.components);
export default __sfc_main;
</script>
